import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../customComponents/breadcrumb/CustomBreadcrumb";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";
import CandidateDetailsCard from "../../Partner/pagesComponents/CandidateDetailsCard";
import CandidateEditProfile from "../../Partner/pagesComponents/CandidateEditProfile";
import "../../../src/assets/css/styleCompanyBench.css";
import CandidateEducationDetails from "../../Partner/pagesComponents/CandidateEducationDetails";
import { FiEdit, FiUserPlus } from "react-icons/fi";
import CandidateWorkExperience from "../../Partner/pagesComponents/CandidateWorkExperience";
import CandidateCertification from "../../Partner/pagesComponents/CandidateCertification";
import AddCandidateDetailsWorkExperience from "../../Partner/pagesComponents/AddCandidateDetailsWorkExperience";
import CandidateDetailsWorkExperienceCanvas from "../../Partner/pagesComponents/CandidateDetailsWorkExperienceCanvas";
import AddCandidateDetailsEducation from "../../Partner/pagesComponents/AddCandidateDetailsEducation";
import CandidateDetailsEducationCanvas from "../../Partner/pagesComponents/CandidateDetailsEducationCanvas";
import CandidateDetailsCertificateCanvas from "../../Partner/pagesComponents/CandidateDetailsCertificateCanvas";
import AddCandidateDetailsCertification from "../../Partner/pagesComponents/AddCandidateDetailsCertification";
import { CandidateResumeProfileEdit, candidatesOverview, createPartnerIndividualResume, editIndividualProfile } from "../../Partner/PartnerSlice";
import { useDispatch } from "react-redux";
import { log_debug } from "../../apiHelpers/logger";
import { toast } from "react-toastify";
import Preloader from "../../components/Common/Preloader";

function IndividualProfileSignupDetails() {
  const location = useLocation();
  const [customActiveTab, setCustomActiveTab] = useState("0");
  const [resumes, setResumes] = useState([]);
  const [verticalActiveTab1, setverticalActiveTab1] = useState("1");
  const [IsAddOpenWork, setIsAddOpenWork] = useState(false);
  const [IsEditOpenWork, setIsEditOpenWork] = useState(false);
  const [CandidateUserTabWork, setCandidateUserTabWork] = useState([]);
  const [workexperience, setWorkexperience] = useState([]);
  const [educationdetails, setEducationdetails] = useState([]);
  const [certificationdetails, setCertificationdetails] = useState([]);
  const [IsEditOpenCertification, setIsEditOpenCertification] = useState(false);
  const [pageloading, setpageloading] = useState(false);

  const [IsAddOpenEducation, setIsAddOpenEducation] = useState(false);
  const [IsEditOpenEducation, setIsEditOpenEducation] = useState(false);
  const [IsAddOpenCertification, setIsAddOpenCertification] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({});
  const [isEdit,setIsEdit] = useState(location.state?.isEdit || false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const individualGuid = location.state?.individualGuid || [];
  const individualResumes = location.state?.individualResumes || [];
  

  //console.log("individual received resumes", individualResumes);

  useEffect(() => {
    // Extract resumes dynamically and push them to the setResumes state
    if (individualResumes.length > 0) {
      const extractedResumes = individualResumes.flatMap(
        (resume) => resume?.candidates?.data?.resumes || []
      );
      setResumes((prevResumes) => [...prevResumes, ...extractedResumes]);
    }
  }, []); // Add individualResumes as dependency to the useEffect

  useEffect(() => {
    //console.log("parsed resumes", resumes);
    setUpdatedUser(resumes[0]);
    setWorkexperience(resumes[0]?.work_experiences);
    setEducationdetails(resumes[0]?.educational_qualifications);
    setCertificationdetails(resumes[0]?.certifications || []);
  }, [resumes]);

  //console.log("extracted resumes", resumes);

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const toggleVerticaltab1 = (tab) => {
    if (verticalActiveTab1 !== tab) {
      setverticalActiveTab1(tab);
    }
  };

  const handleprofiledetails = (data) => {
    setUpdatedUser(data);
    handlenext();
  }
  const handlenext = () => {
    const tabcount = Number(verticalActiveTab1) + 1;
    setverticalActiveTab1(tabcount.toString());
  };

  useEffect(() => {
    //console.log("edited user data", updatedUser)
  },[updatedUser])

  const handleAddWork = () => {
    setIsAddOpenWork(true);
  };

  const handleEditWork = () => {
    setIsEditOpenWork(true);
  };

  const handleAddWorkExperience = (data) => {
    //console.log("experience data", workexperience);
    setWorkexperience([data, ...workexperience]);
  };

  const handleworkexpdelete = (index) => {
    setWorkexperience((prevArray) =>
      prevArray.filter((_, indexval) => indexval !== index)
    );
  };

  const handleUpdateExp = (data) => {
    setWorkexperience(data);
    setIsEditOpenWork(!IsEditOpenWork);
  };

  const handleEditEducation = () => {
    setIsEditOpenEducation(true);
  };

  const handleAddEducation = () => {
    setIsAddOpenEducation(true);
  };

  const handleAddEducationDetails = (data) => {
    setEducationdetails([data, ...educationdetails]);
  };

  const handleEducationDelete = (index) => {
    setEducationdetails((prevArray) =>
      prevArray.filter((_, indexval) => indexval !== index)
    );
  };

  const handleSaveChangesEducation = (data) => {
    setEducationdetails(data);
    setIsEditOpenEducation(!IsEditOpenEducation);
  };

  const handleAddCertification = () => {
    setIsAddOpenCertification(true);
  };

  const handleEditCertification = () => {
    setIsEditOpenCertification(true);
  };

  const handleAddCertificationdetails = (data) => {
    setCertificationdetails([data, ...certificationdetails]);
    setIsAddOpenCertification(false);
  };

  const handleSaveChangesCertifications = (data) => {
    setCertificationdetails(data);
    setIsEditOpenCertification(!IsEditOpenCertification);
  };

  const handledeletecertification = (index) => {
    setCertificationdetails((prevArray) =>
      prevArray.filter((_, indexval) => indexval !== index)
    );
  };

  const handleSubmit = () => {
    console.log("updated user", updatedUser)
    setpageloading(true);
    const userdetails = {
      individual : {
        full_name: updatedUser.full_name,
        date_of_birth: updatedUser.date_of_birth,
        email_id: updatedUser.email,
        contact_no: updatedUser.contact_number,
        address:updatedUser.address,
      work_experiences: workexperience,
      educational_qualifications: educationdetails,
      certifications: certificationdetails,
      linkedin_url: updatedUser.linkedin_url,
      summary: updatedUser.summary,
      resume_url: updatedUser.resume_url,
      professional_role: updatedUser.professional_role,
      availability: updatedUser.availability,
      monthly_salary: updatedUser.monthly_salary,
      relevant_experience: updatedUser.relevant_experience,
      prefered_locations: updatedUser.prefered_locations,
      current_location: updatedUser.current_location,
      notice_period: updatedUser.notice_period,
      overall_experience: updatedUser.overall_experience,
      programming_languages: updatedUser.skills,  
    }
    }
    //console.log("user submit details",userdetails)
    if(isEdit){
      const userdetails = {
        individual : {
          full_name: updatedUser.full_name,
          date_of_birth: updatedUser.date_of_birth,
          email: updatedUser.email,
          contact_number: updatedUser.contact_number,
          address:updatedUser.address,
        work_experiences: workexperience,
        educational_qualifications: educationdetails,
        certifications: certificationdetails,
        linkedin_url: updatedUser.linkedin_url,
        summary: updatedUser.summary,
        resume_url: updatedUser.resume_url,
        professional_role: updatedUser.professional_role,
        availability: updatedUser.availability,
        monthly_salary: updatedUser.monthly_salary,
        relevant_experience: updatedUser.relevant_experience,
        prefered_locations: updatedUser.prefered_locations,
        current_location: updatedUser.current_location,
        notice_period: updatedUser.notice_period,
        overall_experience: updatedUser.overall_experience,
        skills: updatedUser.skills,  
      }
      }
      dispatch(editIndividualProfile({data:userdetails,guid:updatedUser?.guid})).then((res) => {
        log_debug("user profile data response", res.payload)
        if(res.payload.success === true ){
          setpageloading(false)
          toast.success("Candidate Updated Successfully");
          setTimeout(() => {
          navigate("/mycandidate")  
          }, 3000);
          
        }else if(res.payload.success === false){
          setpageloading(false);
          toast.warning(res.payload.error.message);
          setTimeout(() => {
          navigate("/mycandidate")  
          }, 3000);
          
        }
      })
  }else{
    dispatch(createPartnerIndividualResume(userdetails)).then((res) => {
      log_debug("user profile data response", res.payload)
      if(res.payload.success === true ){
        setpageloading(false)
        toast.success("Candidate Created Successfully");
        setTimeout(() => {
        navigate("/mycandidate")  
        }, 5000);
        
      }else if(res.payload.success === false){
        setpageloading(false);
        toast.warning(res.payload.error.message);
        setTimeout(() => {
        navigate("/mycandidate")  
        }, 5000);
        
      }
    })
  }
  }

  return (
    <React.Fragment>
      {pageloading && <Preloader/>}
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    {resumes.map((resume, index) => (
                      <div key={index} className="col-lg-3">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === String(index),
                            })}
                            onClick={() => {
                              toggleCustom(String(index));
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>

                            <span
                              className="d-none d-sm-block"
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "#5156BE",
                                textTransform: "uppercase",
                              }}
                            >
                              {resume?.full_name || "No Name"}
                            </span>
                          </NavLink>
                        </NavItem>
                      </div>
                    ))}
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    {resumes.map((resume, index) => (
                      <TabPane tabId={String(index)} key={index}>
                        <Row>
                          <div
                            id="progrss-wizard"
                            className="twitter-bs-wizard"
                          >
                            <Row>
                              <Col lg={1}>
                                <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                                  <Nav vertical className="form-vertical">
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          "mb-2": true,
                                          active: verticalActiveTab1 === "1",
                                        })}
                                        onClick={() => {
                                          toggleVerticaltab1("1");
                                        }}
                                      >
                                        <div
                                          className="step-icon"
                                          data-bs-toggle="tooltip"
                                          id="ProfileDetails"
                                        >
                                          <i className="mdi mdi-badge-account-horizontal-outline font-size-24"></i>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="ProfileDetails"
                                          >
                                            Profile Details
                                          </UncontrolledTooltip>
                                        </div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          "mb-2": true,
                                          active: verticalActiveTab1 === "2",
                                        })}
                                        onClick={() => {
                                          toggleVerticaltab1("2");
                                        }}
                                      >
                                        <div
                                          className="step-icon"
                                          data-bs-toggle="tooltip"
                                          id="WorkExperience"
                                        >
                                          <i className="mdi mdi-briefcase-account-outline font-size-24"></i>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="WorkExperience"
                                          >
                                            Work Experience
                                          </UncontrolledTooltip>
                                        </div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          "mb-2": true,
                                          active: verticalActiveTab1 === "3",
                                        })}
                                        onClick={() => {
                                          toggleVerticaltab1("3");
                                        }}
                                      >
                                        <div
                                          className="step-icon"
                                          data-bs-toggle="tooltip"
                                          id="Education"
                                        >
                                          <i className="mdi-book-education-outline mdi font-size-24"></i>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="Education"
                                          >
                                            Education
                                          </UncontrolledTooltip>
                                        </div>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          "mb-2": true,
                                          active: verticalActiveTab1 === "4",
                                        })}
                                        onClick={() => {
                                          toggleVerticaltab1("4");
                                        }}
                                      >
                                        <div
                                          className="step-icon"
                                          data-bs-toggle="tooltip"
                                          id="Certifications"
                                        >
                                          <i className="mdi mdi-certificate-outline font-size-24"></i>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="Certifications"
                                          >
                                            Certifications
                                          </UncontrolledTooltip>
                                        </div>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </ul>
                              </Col>
                              <Col lg={11} className="mt-3">
                                <TabContent
                                  activeTab={verticalActiveTab1}
                                  className="twitter-bs-wizard-tab-content ms-4 pt-0"
                                >
                                  <TabPane tabId="1">
                                    <Row>
                                      <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Profile Details :
                                            </h5>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Row>
                                    <Row>
                                      <Col className="col-lg-12">
                                        <Row className="col-lg-12">
                                          <Col md={12}>
                                            <CandidateEditProfile
                                              data={resume && resume}
                                              handleProfileSave={handleprofiledetails}
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </TabPane>

                                  <TabPane tabId="2">
                                    <Row>
                                      <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Work Experience Details :
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2  mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddWork(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditWork(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                              Edit
                                            </Button>
                                          </Col>
                                          {IsAddOpenWork && (
                                            <AddCandidateDetailsWorkExperience
                                              handleSave={
                                                handleAddWorkExperience
                                              }
                                              isOpen={IsAddOpenWork}
                                              toggle={() =>
                                                setIsAddOpenWork(!IsAddOpenWork)
                                              }
                                            />
                                          )}
                                          {IsEditOpenWork && (
                                            <CandidateDetailsWorkExperienceCanvas
                                              isOpen={IsEditOpenWork}
                                              data={workexperience}
                                              // editedData={editedData}
                                              handleSave={(editedData) =>
                                                handleUpdateExp(editedData)
                                              }
                                              // formData={editedData}
                                              toggle={() =>
                                                setIsEditOpenWork(
                                                  !IsEditOpenWork
                                                )
                                              }
                                            />
                                          )}
                                        </Row>
                                      </div>
                                    </Row>
                                    <Row>
                                      <Col className="col-lg-12">
                                        <Row className="col-lg-12">
                                          <Col md={12}>
                                            <CandidateWorkExperience
                                              data={workexperience}
                                              handleDeleteExp={
                                                handleworkexpdelete
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="d-flex justify-content-end">
                                        <Button
                                          color="primary"
                                          onClick={handlenext}
                                        >
                                          Next
                                        </Button>
                                      </Col>
                                    </Row>
                                  </TabPane>

                                  <TabPane tabId="3">
                                    <Row>
                                      <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Education Details :
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2  mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddEducation(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditEducation(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                              Edit
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Row>
                                    <Row>
                                      <Col className="col-lg-12">
                                        <Row className="col-lg-12">
                                          <Col md={12}>
                                            <CandidateEducationDetails
                                              data={educationdetails}
                                              handleDeleteEdu={
                                                handleEducationDelete
                                              }
                                            />
                                          </Col>
                                        </Row>
                                        {IsAddOpenEducation && (
                                          <AddCandidateDetailsEducation
                                            handleSave={
                                              handleAddEducationDetails
                                            }
                                            isOpen={IsAddOpenEducation}
                                            toggle={() =>
                                              setIsAddOpenEducation(
                                                !IsAddOpenEducation
                                              )
                                            }
                                          />
                                        )}
                                        {IsEditOpenEducation && (
                                          <CandidateDetailsEducationCanvas
                                            isOpen={IsEditOpenEducation}
                                            data={educationdetails}
                                            // editedData={editedData}
                                            handleSave={(editedData) =>
                                              handleSaveChangesEducation(
                                                editedData
                                              )
                                            }
                                            // formData={editedData}
                                            toggle={() =>
                                              setIsEditOpenEducation(
                                                !IsEditOpenEducation
                                              )
                                            }
                                          />
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="d-flex justify-content-end">
                                        <Button
                                          color="primary"
                                          onClick={handlenext}
                                        >
                                          Next
                                        </Button>
                                      </Col>
                                    </Row>
                                  </TabPane>

                                  <TabPane tabId="4">
                                    <Row>
                                      <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                                        <Row className="col-lg-12">
                                          <Col className="col-lg-6 col-md-12">
                                            <h5 className="font-size-16 mt-2 text-primary">
                                              Certification Details :
                                            </h5>
                                          </Col>
                                          <Col className="col-lg-6 col-md-12 text-end">
                                            <Button
                                              color="success"
                                              type="button"
                                              className="text-white my-2  mx-1 rounded"
                                              onClick={(e) => {
                                                handleAddCertification(e);
                                              }}
                                            >
                                              <FiUserPlus className="mx-1" />
                                              Add
                                            </Button>
                                            <Button
                                              color="primary"
                                              type="button"
                                              className="text-white my-2 rounded"
                                              onClick={(e) => {
                                                handleEditCertification(e);
                                              }}
                                            >
                                              <FiEdit className="mx-1" />
                                              Edit
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Row>
                                    <Row>
                                      <Col className="col-lg-12">
                                        <Row className="col-lg-12">
                                          <Col md={12}>
                                            <CandidateCertification
                                              data={certificationdetails}
                                              handlecertificationdelete={
                                                handledeletecertification
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                      {IsAddOpenCertification && (
                                        <AddCandidateDetailsCertification
                                          handleSave={(data) =>
                                            handleAddCertificationdetails(data)
                                          }
                                          isOpen={IsAddOpenCertification}
                                          toggle={() =>
                                            setIsAddOpenCertification(
                                              !IsAddOpenCertification
                                            )
                                          }
                                        />
                                      )}
                                      {IsEditOpenCertification && (
                                        <CandidateDetailsCertificateCanvas
                                          isOpen={IsEditOpenCertification}
                                          data={certificationdetails || []}
                                          // editedData={editedData}
                                          handleSave={(editedData) =>
                                            handleSaveChangesCertifications(
                                              editedData
                                            )
                                          }
                                          // formData={editedData}
                                          toggle={() =>
                                            setIsEditOpenCertification(
                                              !IsEditOpenCertification
                                            )
                                          }
                                        />
                                      )}
                                    </Row>
                                    <Row>
                                      <Col className="d-flex justify-content-end">
                                        <Button
                                          color="primary"
                                          onClick={handleSubmit}
                                        >
                                          Save
                                        </Button>
                                      </Col>
                                    </Row>
                                  </TabPane>
                                </TabContent>
                              </Col>
                            </Row>
                          </div>
                        </Row>
                      </TabPane>
                    ))}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default IndividualProfileSignupDetails;
